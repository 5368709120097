import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query HeaderRaQuery {
    craft {
      entry(section: "headerRa", site: ["robActon"]) {
        ... on Craft_headerRa_headerRa_Entry {
          headerLinks {
            ... on Craft_headerLinks_link_BlockType {
              uid
              headLink {
                url
                text
              }
              sublinks {
                ... on Craft_sublinks_BlockType {
                  uid
                  sublink {
                    url
                    text
                  }
                }
              }
            }
          }
          button: link0 {
            url
            text
          }
          altLink1: link1 {
            url
            text
          }
          altLink2: link2 {
            url
            text
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const { headerLinks, button, altLink1, altLink2 } = entry;
  const nav = headerLinks?.map(links => {
    return {
      ...links?.headLink,
      children: links?.sublinks?.map(sub => sub?.sublink),
    };
  });
  nav.push({ ...button, button: true });
  return {
    nav,
    altLink1,
    altLink2,
  };
};

const useData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useData;
